import React, {useState} from 'react';
import './App.css';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-nord_dark";
import {MessageBuilder, Webhook} from "discord-webhook-node";
import ReactMarkdown from 'react-markdown';

const replaceVariable = (s: string) => {
    let replaced = s;
    if (s) {
        [
            {
                key: "invite_url",
                value: "https://discord.com/oauth2/authorize?client_id=730839152681025568&permissions=4228906225&scope=applications.commands%20bot"
            },
            {
                key: "website_url",
                value: "https://levely.me"
            }
        ]
            .forEach(r => replaced = replaced.replace("{{ ." + r.key + " }}", r.value));
    }
    return replaced;
}

function sendWebhook(webhookUrl: string, json: any, k: string) {
    const webhook = new Webhook(webhookUrl);
    webhook.setAvatar("https://media.discordapp.net/attachments/793117391126134814/907073686098559007/Levely.png?width=609&height=609");
    webhook.setUsername("Webhook Levely")
    const message = new MessageBuilder()
        .setTitle(replaceVariable(json.embeds[k].title))
        .setColor(5959331)
        .setDescription(replaceVariable(json.embeds[k].description ?? ""))
        .setFooter(replaceVariable(json.embeds[k].footer ?? ""), "https://media.discordapp.net/attachments/793117391126134814/907073686098559007/Levely.png?width=609&height=609")
    webhook.send(message)
}

function App() {
    const [json, setJson] = useState<Record<string, any>>({embeds: {}});
    const [error, setError] = useState<string | undefined>(undefined)
    const [webhookUrl, setWebhookUrl] = useState("");
    const [bulkSend, setBulkSend] = useState<string[]>([])

    return (
        <div className="App" style={{display: "flex", flexDirection: "column"}}>
            {error ? <div style={{background: "red", color: "white"}}>{error}</div> :
                <div style={{background: "green", color: "white"}}>Valid Json</div>}
            <input type={"text"} placeholder={"Webhook URL"} onChange={(n) => setWebhookUrl(n.currentTarget.value)}/>
            <div style={{display: "flex", flexDirection: "row"}}>
                <AceEditor
                    mode="json"
                    theme="nord_dark"
                    width={"60vw"}
                    height={"90vh"}
                    onChange={(n) => {
                        try {
                            const newJson = JSON.parse(n);
                            setJson(newJson)
                            setError(undefined);
                        } catch (e) {
                            setError("Invalid Json");
                        }
                    }}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{$blockScrolling: true}}
                    defaultValue={JSON.stringify(json)}
                />
                <div style={{
                    flexDirection: "column",
                    display: "flex",
                    overflowX: "scroll",
                    height: "90vh",
                    width: "40vw"
                }}>
                    <button onClick={() => bulkSend.forEach(b => sendWebhook(webhookUrl, json, b))}>Send All Selected
                        Webhooks
                    </button>
                    {Object.keys(json.embeds).map(k => {
                        const embed = json.embeds[k];
                        return (
                            <div key={k}>
                                <div>
                                    <div style={{
                                        borderLeft: "4px #5aeea3 solid",
                                        borderColor: "#5aeea3",
                                        borderRadius: "4px",
                                        display: "flex",
                                        backgroundColor: "#2f3136",
                                        margin: "0.5rem",
                                        padding: "0.5rem",
                                        gap: "0.25rem",
                                        flexDirection: "column",
                                        width: "max-content",
                                        alignSelf: "flex-start",
                                        maxWidth: "460px"
                                    }}>
                                        <div style={{
                                            color: "white",
                                            fontSize: "1rem",
                                            fontWeight: 600,
                                            alignSelf: "flex-start"
                                        }}><ReactMarkdown>{replaceVariable(embed.title)}</ReactMarkdown>
                                        </div>
                                        {embed.description && <div style={{
                                            color: "white",
                                            fontSize: "0.875rem",
                                            fontWeight: 400,
                                            textAlign: "left"
                                        }}>
                                            <ReactMarkdown>{replaceVariable(embed.description)}</ReactMarkdown>
                                        </div>}
                                        {embed.footer && <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            lineHeight: "1rem",
                                            fontWeight: 500,
                                            textAlign: "left"
                                        }}><img src={"./levely.png"}
                                                style={{
                                                    borderRadius: "999px",
                                                    height: "1rem",
                                                    margin: "0.25rem",
                                                    marginTop: "auto",
                                                    marginBottom: "auto"
                                                }}/>
                                            <div style={{
                                                color: "white",
                                                fontSize: "0.75rem",
                                                lineHeight: "1rem",
                                                fontWeight: 500,
                                                textAlign: "left",
                                                marginTop: "auto",
                                                marginBottom: "auto"
                                            }}>
                                                <ReactMarkdown>{replaceVariable(embed.footer)}</ReactMarkdown>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <button onClick={() => {
                                    sendWebhook(webhookUrl, json, k)
                                }}>Send Webhook
                                </button>
                                <input type={"checkbox"} onChange={(v) => {
                                    if (v.currentTarget.value) {
                                        const newBulkSend = Object.assign([], bulkSend);
                                        newBulkSend.push(k);
                                        setBulkSend(newBulkSend)
                                    } else {
                                        const newBulkSend = Object.assign([], bulkSend);
                                        newBulkSend.filter(i => i !== k);
                                        setBulkSend(newBulkSend)
                                    }
                                }}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default App;
